/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { FC } from 'react';
import { useRouter } from 'next/router';
import ErrorPage from 'next/error';
import Head from '@components/Head';
import { getRegion } from '@framework/api/utils/maxify';
import type { Category } from '@framework/api/types';
import PageContent from '@components/Content/PageContent';

type Props = {
  envName?: string;
  baseUrl?: string;
  locale?: string;
  locales?: string;
  category?: Category;
};

const CategoryPage: FC<Props> = ({ baseUrl, category }) => {
  const router = useRouter();
  const { query, locale } = router;
  const region = getRegion(locale);

  return !category?.slug ? (
    <ErrorPage statusCode={404} />
  ) : (
    <>
      <Head
        baseUrl={baseUrl}
        canonical={`${baseUrl}/category/${query.slug}${
          query['subcategory-slug'] ? `/${query['subcategory-slug']}` : ''
        }`}
        cmsData={category.seo}
      />
      <PageContent data={category} region={region} />
    </>
  );
};

export default CategoryPage;
