
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/category/[slug]/[subcategory-slug]",
      function () {
        return require("private-next-pages/category/[slug]/[subcategory-slug].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/category/[slug]/[subcategory-slug]"])
      });
    }
  