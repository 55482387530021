import * as Sentry from '@sentry/nextjs';
import { GetStaticProps, GetStaticPaths, InferGetStaticPropsType } from 'next';
import getUrlByEnv from '@utils/get-url-by-env';
import getNav from '@framework/api/operations/get-nav';
import getAllCategories from '@framework/api/operations/get-all-categories';
import getCategory from '@framework/api/operations/get-category';
import type { Category } from '@framework/api/types';
import Layout from '@components/Layout';
import decorateCategoryContent from '@utils/decorate-category-content';
import CategoryPageComponent from '@components/CategoryPage';
import { STAGING, ROUTE_CATEGORY, REVALIDATE_VALUES } from '@constants';

export const getStaticProps: GetStaticProps = async ({
  locale,
  locales,
  params,
  preview,
}) => {
  const envName =
    process.env.VERCEL_GITHUB_COMMIT_REF === STAGING
      ? process.env.NEXT_PUBLIC_STAGING_ENV_NAME
      : process.env.NEXT_PUBLIC_ENV_NAME;

  const baseUrl = getUrlByEnv(envName);

  const slug = Array.isArray(params['subcategory-slug'])
    ? params['subcategory-slug'][0]
    : params['subcategory-slug'];

  const [category, nav] = await Promise.all([
    getCategory({ slug, locale, preview }),
    getNav({ slug: 'main', locale, preview }),
  ]);

  if (!category) {
    if (!slug.endsWith('.css.map')) {
      Sentry.captureException(
        new Error(`Category with slug '${slug}' not found`)
      );
    }

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.notFound,
    };
  }

  if (
    !Array.isArray(category.content) ||
    (Array.isArray(category.content) && category.content.length === 0)
  ) {
    Sentry.captureException(
      new Error(`Category with slug '${slug}' has no content`)
    );

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.notFound,
    };
  }

  const { content, ...rest } = category;

  const decoratedContent = await decorateCategoryContent(content, locale);

  return {
    props: {
      preview: preview || false,
      envName,
      baseUrl,
      locale,
      locales: JSON.stringify(locales),
      category: { content: decoratedContent, ...rest },
      nav,
    },
    revalidate: REVALIDATE_VALUES.standard,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const allCategories = await getAllCategories();
  const childCategories = allCategories.filter(c => c.parentCategory);

  // TODO - wire up static paths per locale
  const buildPaths = (cats: Array<Category>): Array<string> => {
    const paths = [];

    cats.forEach(category => {
      paths.push(
        `/${ROUTE_CATEGORY}/${category.parentCategory.slug}/${category.slug}`
      );
    });

    return paths;
  };

  return {
    paths: buildPaths(childCategories) || [],
    fallback: 'blocking',
  };
};

const CategoryPage: InferGetStaticPropsType<typeof getStaticProps> = ({
  ...rest
}) => <CategoryPageComponent {...rest} />;

CategoryPage.Layout = Layout;

export default CategoryPage;
